/* API */
import api from '@/api/ajax/settings';

/* 型別 */
import type { AxiosPromise } from 'axios';
import type ResponseBaseModel from '@sms/common/interfaces/ResponseBaseModel';
import type LocationSet from '@sms/common/models/LocationSet';

export interface GetLocationTreePayload {
  NeedFlatten?: boolean;
  TypeFilter?: string[];
  OrderBy?: string;
}

export interface GetMemberLocationTreePayload extends GetLocationTreePayload {
  NeedFilterExclusiveCustomer?: boolean;
  CustomerId?: string;
}

export default {
  get(locationId: string): AxiosPromise<ResponseBaseModel<LocationSet>> {
    return api.get(`/Location/${locationId}`);
  },

  getLocationTree(getLocationTreePayload?: GetLocationTreePayload): AxiosPromise<ResponseBaseModel<LocationSet[]>> {
    return api.post('/Location/GetLocationTree', getLocationTreePayload);
  },

  getMemberLocationTree(
    getMemberLocationTreePayload?: GetMemberLocationTreePayload
  ): AxiosPromise<ResponseBaseModel<LocationSet[]>> {
    return api.post('/Location/GetMemberLocationTree', getMemberLocationTreePayload);
  }
};
